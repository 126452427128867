// * * * * * * * * * * * * * *
// * * Import core styles  * *
// * * * * * * * * * * * * * *


// Includes Bootstrap and styling shared across
// all themes using the Start Bootstrap Pro
// design language

@import './core/styles';
//@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';

// * * * * * * * * * * * * * * * * * *
// * * Import SB Admin Pro styles  * *
// * * * * * * * * * * * * * * * * * *

// Includes styling specific to the SB Admin Pro
// theme - must load after core styles

@import './admin/styles';

// * * * * * * * * * * * * * * * * * * * *
// * * Import Angular specific styles  * *
// * * * * * * * * * * * * * * * * * * * *

// Styling specific to your project
// The file imported below is intentionally blank and should
// be used to create styling specific to your project

@import './sb-admin-pro-angular';

// * * * * * * * * * * * * * * *
// * * Import project styles * *
// * * * * * * * * * * * * * * *

// Styling specific to your project
// The below file is intentionally blank and should
// be used to create styling specific to your project

@import './project';

@import '../../node_modules/quill/dist/quill.core.css';
@import '../../node_modules/quill/dist/quill.bubble.css';
@import '../../node_modules/quill/dist/quill.snow.css';

//MDB Angular
//@import "@fortawesome/fontawesome-free/css/all.css";

@import 'plasma-scss-overrides';
@import 'plasma-scss-color-overrides';
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';

