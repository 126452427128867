$accordion-bg: $white;
$accordion-border-width: #E4EDF5;

$accordion-button-active-bg: $white;
$accordion-button-active-color: $white;

$accordion-icon-color: #4C6CA9;
$accordion-icon-active-color: #4C6CA9;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='26' height='16' viewBox='0 0 26 16' fill='none'><path d='M3 3L13 13L23 3' stroke='#4C6CA9' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='26' height='16' viewBox='0 0 26 16' fill='none'><path d='M3 3L13 13L23 3' stroke='#4C6CA9' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/></svg>");

$accordion-borderless-button-bgc: $white;


.accordion-borderless .accordion-item:first-child {
  border-top: 2px #e4edf5 solid!important;
}

.accordion-borderless .accordion-item {
  border-bottom: 2px #e4edf5 solid!important;
}

.accordion-button {
  color: #394751!important;
  font-size: 1.5rem!important;
  font-weight: 400!important;
  padding-left: 0!important;
  @media screen and (max-width: 767px) {
    font-size: 16px!important;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    background-color: $gray-300!important;
  }

  &:after {
    height: 12px!important;
    order: -1!important;
    margin-left: 0!important;
    margin-right: 1.5rem!important;
  }
}

.accordion-body {
  font-size: 1.25rem!important;
  color: #476273!important;
  font-weight: 400!important;
  padding: 2px 0 30px 0!important;
  @media screen and (max-width: 767px) {
    font-size: 14px!important;
  }
}
