$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

.carousel {
  padding-bottom: 12rem;
  overflow: hidden!important;

  .carousel-indicators {
    bottom: 8.5rem!important;
    button {
      background-color: #4c6ca9!important;
    }
  }

  .carousel-inner {
    overflow: visible!important;

    .carousel-item {
      .carousel-caption {
        top: 108% !important;
        left: 0!important;
        right: 0!important;
        transition: opacity 0.1s ease-in-out;
      }

      &.active .carousel-caption {
        animation: fadeIn 0.1s forwards;
      }

      &.carousel-item-next .carousel-caption,
      &.carousel-item-prev .carousel-caption {
        animation: fadeOut 0.1s forwards;
      }

    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    bottom: 12rem!important;
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
