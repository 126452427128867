@font-face {
  font-family: 'Lovelo';
  src: url('/assets/fonts/lovelo/Lovelo-Black.otf');
}

@font-face {
  font-family: 'Brandon-Grotesque';
  src: url('/assets/fonts/brandon-grotesque/brandon-grotesque-black.otf');
}

@font-face {
  font-family: 'Russo One';
  src: url('/assets/fonts/russo-one/RussoOne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
