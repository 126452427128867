nav {
  &.translate-bar-visible {
    top: 38px !important; // Adjust this value based on the height of the Google Translate bar
  }
}

mdb-sidenav {
  .translate-bar-visible {
    margin-top: 38px !important;
  }
}
