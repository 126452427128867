//
// Variable overrides
//

// This file is intentionally blank
// Use this file to override any core SCSS variables
$input-line-height: 1.6;
$input-font-size: 16px;

.btn {
  text-transform: initial;
}
