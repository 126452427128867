@import '../plasma-scss-color-overrides';

.text-50 {
  color: $text-50;
}
.text-100 {
  color: $text-100;
}
.text-200 {
  color: $text-200;
}
.text-300 {
  color: $text-300;
}
.text-400 {
  color: $text-400;
}
.text-500 {
  color: $text-500;
}
.text-600 {
  color: $text-600;
}
.text-700 {
  color: $text-700;
}
.text-800 {
  color: $text-800;
}
.text-900 {
  color: $text-900;
}
.text-950 {
  color: $text-950;
}

.main-header {
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-sub-header {
  font-size: 2rem;
  font-weight: 400;
  line-height: 3.5rem;
}

.component-header {
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.component-subheader {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3.5rem;
}

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6rem;
}
