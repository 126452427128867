
$primary: #005de7;
$secondary: #4c6ca9;
$success: #009e78;
$info: #48bdff;
$danger: #ff5029;
$warning: #ffa82d;

$primary-50: #edf9ff;
$primary-100: #d6efff;
$primary-200: #b5e4ff;
$primary-300: #83d5ff;
$primary-400: #48bdff;
$primary-500: #1e9bff;
$primary-600: #067bff;
$primary-700: #005de7;
$primary-800: #084fc5;
$primary-900: #0d469b;
$primary-950: #0e2b5d;
$primary-1000:#04142e;

$secondary-50: #f3f8fb;
$secondary-100: #e4edf5;
$secondary-200: #cfe0ee;
$secondary-300: #aecce2;
$secondary-400: #87b0d3;
$secondary-500: #6a95c7;
$secondary-600: #567eb9;
$secondary-700: #4c6ca9;
$secondary-800: #425a8b;
$secondary-900: #394c6f;
$secondary-950: #263045;

$success-50: #ebfef6;
$success-100: #d0fbe8;
$success-200: #a4f6d6;
$success-300: #6aebc1;
$success-400: #2fd8a6;
$success-500: #0abf90;
$success-600: #009e78;
$success-700: #007c62;
$success-800: #03624e;
$success-900: #045041;
$success-950: #012d26;

$danger-50: #fff1f0;
$danger-100: #ffe0de;
$danger-200: #ffc7c2;
$danger-300: #ffa098;
$danger-400: #ff3a2a;
$danger-500: #ff3a2a;
$danger-600: #e91807;
$danger-700: #d11304;
$danger-800: #ac1408;
$danger-900: #8e180e;
$danger-950: #4e0701;

$warning-50: #fff8eb;
$warning-100: #ffecc6;
$warning-200: #ffd788;
$warning-300: #ffbd4a;
$warning-400: #ffa82d;
$warning-500: #f97e07;
$warning-600: #dd5902;
$warning-700: #b73b06;
$warning-800: #942d0c;
$warning-900: #7a250d;
$warning-950: #461102;

$text-50: #f3f7f8;
$text-100: #c5d5dc;
$text-200: #c5d5dc;
$text-300: #6e92a2;
$text-400: #6e92a2;
$text-500: #476273;
$text-600: #476273;
$text-700: #394751;
$text-800: #394751;
$text-900: #161c21;
$text-950: #161c21;

$info-400: #48bdff;
$info-500: #48bdff;
$info-900: #0d469b;

//Classroom Colors

$classroom-red-600: #da453e;
$classroom-red-100: #f8dad8;
$classroom-red-50: #fcedec;

$classroom-orange-600: #e7760d;
$classroom-orange-100: #fae4cf;
$classroom-orange-50: #fdf2e7;

$classroom-lime-600: #70a224;
$classroom-lime-100: #e2ecd3;
$classroom-lime-50: #f1f6ea;

$classroom-green-600: #086237;
$classroom-green-100: #cee0d7;
$classroom-green-50: #e7f0eb;

$classroom-teal-600: #00a1c5;
$classroom-teal-100: #ccecf3;
$classroom-teal-50: #e6f6fa;

$classroom-blue-600: #0d79ce;
$classroom-blue-100: #cfe3f5;
$classroom-blue-50: #e7f2fb;

$classroom-pink-600: #cb73d4;
$classroom-pink-100: #f5e3f6;
$classroom-pink-50: #faf1fb;

$classroom-purple-600: #813adb;
$classroom-purple-100: #e6d8f8;
$classroom-purple-50: #f3ecfc;

$classroom-navy-600: #4C6CA9;
$classroom-navy-100: #99BBDF;
$classroom-navy-50: #EDF0F6;

$divider-bg: #cfe0ee;

$headings-color: $text-900;

$input-border-color: #4c6ca9;

$navbar-light-color: rgba($primary, 0.55);
$navbar-light-hover-color: rgba($primary, 0.7);
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($primary, 0.3);

$sidenav-link-padding-y: 2rem;
$sidenav-link-padding-x: 3rem;
$sidenav-link-transition: all 50ms ease-in-out;

$body-color: #394751;

$tooltip-max-width: 250px;
$tooltip-opacity: 0.9;
$tooltip-margin: null;

$tooltip-color: $text-900;
$tooltip-padding-y: 6px;
$tooltip-padding-x: 16px;
$tooltip-font-size: 14px;
$tooltip-bg: #e4edf5;
$tooltip-border-radius: 0.25rem;

$box-shadow-1: 0px 0px 6px 0px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-2: 0px 0px 12px 0px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-3: 0px 0px 12px 2px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-4: 0px 0px 12px 3px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-5: 0px 0px 12px 4px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-6: 0px 0px 12px 5px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-7: 0px 0px 12px 6px rgb(207,224,238), 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-1-strong:  0px 0px 8px 4px rgba(38, 48, 69, 0.33), 0 2px 4px rgba(0, 0, 0, 0.05);

.box-shadow-1{box-shadow: $box-shadow-1;}
.box-shadow-2{box-shadow: $box-shadow-2;}
.box-shadow-3{box-shadow: $box-shadow-3;}
.box-shadow-4{box-shadow: $box-shadow-4;}
.box-shadow-5{box-shadow: $box-shadow-5;}
.box-shadow-6{box-shadow: $box-shadow-6;}
.box-shadow-7{box-shadow: $box-shadow-7;}
.box-shadow-1-strong{box-shadow: $box-shadow-1-strong;}


