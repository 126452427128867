//Plasma Games Gradient Backgrounds
.bg-gradient-pg-indigo {
  background-color: $pgIndigoGradS;
  background-image: linear-gradient(135deg, $pgIndigoGradS 0%, fade-out($pgIndigoGradE, 0.2) 100%);
}

.bg-gradient-pg-blue {
  background-color: $pgBlueGradS;
  background-image: linear-gradient(135deg, $pgBlueGradS 0%, fade-out($pgBlueGradE, 0.2) 100%);
}

.bg-gradient-pg-green {
  background-color: $pgGreenGradS;
  background-image: linear-gradient(135deg, $pgGreenGradS 0%, fade-out($pgGreenGradE, 0.2) 100%);
}

.bg-gradient-pg-yellow {
  background-color: $pgYellowGradS;
  background-image: linear-gradient(135deg, $pgYellowGradS 0%, fade-out($pgYellowGradE, 0.2) 100%);
}

.bg-gradient-pg-orange {
  background-color: $pgOrangeGradS;
  background-image: linear-gradient(135deg, $pgOrangeGradS 0%, fade-out($pgOrangeGradE, 0.2) 100%);
}

.bg-gradient-pg-red {
  background-color: $pgRedGradS;
  background-image: linear-gradient(135deg, $pgRedGradS 0%, fade-out($pgRedGradE, 0.2) 100%);
}

.bg-gradient-pg-maroon {
  background-color: $pgMaroonGradS;
  background-image: linear-gradient(135deg, $pgMaroonGradS 0%, fade-out($pgMaroonGradE, 0.2) 100%);
}

.bg-gradient-pg-purple {
  background-color: $pgPurpleGradS;
  background-image: linear-gradient(135deg, $pgPurpleGradS 0%, fade-out($pgPurpleGradE, 0.2) 100%);
}

.bg-gradient-pg-dark {
  background-color: $pgDarkGradS;
  background-image: linear-gradient(135deg, $pgDarkGradS 0%, fade-out($pgDarkGradE, 0.2) 100%);
}

.bg-gradient-pg-light {
  background-color: $pgLightGradS;
  background-image: linear-gradient(135deg, $pgLightGradS 0%, fade-out($pgLightGradE, 0.2) 100%);
}
