@import 'plasma-scss-color-overrides';
@import 'plasma/plasma-scss-text-overrides';
@import 'styles/plasma/plasma-scss-hr-overrides';
@import 'plasma/components/pg_nav';


$btn-disabled-opacity: 25%;

.all-buttons{
  text-align: center;
  font-family: Kanit, sans-serif;
  font-size: 1rem;
  line-height: normal;
  padding: 5px 12px 5px 12px;

  &:focus:focus-visible{
    border-radius: 6px;
    border: 2px dashed $text-950;
  }
}

.filled-only{
  box-shadow: none;
  font-weight: 500;
  color: $text-50;
  &:hover{
    box-shadow: none;
  }
}

.outline-only{
  font-weight: 400;
  border-radius: 6px;
  background-color: #FFFFFF !important;
}

.btn-primary {
  @extend .all-buttons;
  @extend .filled-only;
  background-color: $primary;
  &:hover{
    background-color:$primary-800;
  }
  &:active{
    background-color: $primary-900;
  }
  &:focus:focus-visible{
    background-color: $primary-800;
  }
}

.btn-outline-primary {
  @extend .all-buttons;
  @extend .outline-only;
  color:$primary;
  border-color:$primary;
  &:hover{
    background-color:$primary-50 !important;
    border-color:$primary-800;
    color:$primary-800;
  }
  &:active{
    background-color:$primary-100 !important;
    border-color:$primary-900;
    color:$primary-900;

  }
  &:focus:focus-visible{
    background-color:$primary-50 !important;

    color:$primary-800;
  }
}

.btn-secondary {
  @extend .all-buttons;
  @extend .filled-only;
  background-color: $secondary;
  &:hover{
    background-color:$secondary-800;
  }
  &:active{
    background-color: $secondary-900;

  }
  &:focus:focus-visible{
    background-color: $secondary-800;

  }
}

.btn-outline-secondary {
  @extend .all-buttons;
  @extend .outline-only;
  color:$secondary;
  border-color:$secondary;
  &:hover{
    background-color:$secondary-50 !important;
    border-color:$secondary-800;
    color:$secondary-800;
  }
  &:active{
    background-color:$secondary-100 !important;
    border-color:$secondary-900;
    color:$secondary-900;
  }
  &:focus:focus-visible{
    background-color:$secondary-50 !important;
    color:$secondary-800;
  }
}

.btn-success{
  @extend .all-buttons;
  @extend .filled-only;
  background-color: $success;
  &:hover{
    background-color:$success-800;
  }
  &:active{
    background-color: $success-900;
  }
  &:focus:focus-visible{
    background-color:$success-800;

  }
}

.btn-outline-success {
  @extend .all-buttons;
  @extend .outline-only;
  color:$success;
  border-color:$success;
  &:hover{
    background-color:$success-50 !important;
    border-color:$success-800;
    color:$success-800;
  }
  &:active{
    background-color:$success-100 !important;
    border-color:$success-900;
    color:$success-900;
  }
  &:focus:focus-visible{
    background-color:$success-50 !important;
    color:$success-800;
  }
}

.btn-danger{
  @extend .all-buttons;
  @extend .filled-only;
  background-color: $danger-600;
  &:hover{
    background-color:$danger-700;
  }
  &:active{
    background-color: $danger-900;
  }
  &:focus:focus-visible{
    background-color:$danger-700;

  }
}

.btn-outline-danger{
  @extend .all-buttons;
  @extend .outline-only;
  color:$danger-600;
  border-color:$danger-600;
  &:hover{
    background-color:$danger-50 !important;
    border-color:$danger-700;
    color:$danger-700;
  }
  &:active{
    background-color:$danger-100 !important;
    border-color:$danger-900;
    color:$danger-900;
  }
  &:focus:focus-visible{
    background-color:$danger-50 !important;
    color:$danger-700;
  }
}

.btn-warning{
  @extend .all-buttons;
  @extend .filled-only;
  background-color: $warning-500;
  &:hover{
    background-color:$warning-700;
  }
  &:active{
    background-color: $warning-800;
  }
  &:focus:focus-visible{
    background-color:$warning-700;

  }
}

.btn-outline-warning {
  @extend .all-buttons;
  @extend .outline-only;
  color:$warning-500;
  border-color:$warning-500;
  &:hover{
    background-color:$warning-50 !important;
    border-color:$warning-700;
    color:$warning-700;
  }
  &:active{
    background-color:$warning-100 !important;
    border-color:$warning-800;
    color:$warning-800;
  }
  &:focus:focus-visible{
    background-color:$warning-50 !important;

    color:$warning-700;
  }
}

.btn-info{
  @extend .all-buttons;
  @extend .filled-only;
  background-color: $text-400;
  &:hover{
    background-color:$text-700;
  }
  &:active{
    background-color: $text-900;
  }
  &:focus:focus-visible{
    background-color:$text-700;

  }
}

.btn-outline-info {
  @extend .all-buttons;
  @extend .outline-only;
  color:$text-500;
  border-color:$text-500;
  &:hover{
    background-color:$text-50 !important;
    border-color:$text-700;
    color:$text-700;
  }
  &:active{
    background-color:$text-100 !important;
    border-color:$text-900;
    color:$text-900;
  }
  &:focus:focus-visible{
    background-color:$text-50!important;
    color:$text-700;
  }
}
.btn-link {
  @extend .all-buttons;
  @extend .filled-only;
  color: $primary;
  &:hover{
    color: $primary-800;
  }
  &:active{
    color: $primary-900;
  }
  &:focus:focus-visible{
    color: $primary-800;
  }
}

.striped-button{
  @extend .all-buttons;
  border: 2.5px dashed $primary;
  &:hover{
    border: 2.5px dashed $primary-800;
    background: $primary-100;
  }
  &:active{
    border: 2.5px dashed $primary-900;
    background: $primary-200;
  }
}


