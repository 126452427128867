// Override Bootstrap color system

$red: #e81500;
$orange: #f76400;
$yellow: #f4a100;
$green: #00ac69;
$teal: #00ba94;
$cyan: #00cfd5;
$blue: #0061f2;
$indigo: #5800e8;
$purple: #6900c7;
$pink: #e30059;

// Custom grayscale base hue variable
//
// Changing this to adjusts the hue of the grayscale

$grayscale-base-hue: $blue;

// Override Bootstrap grayscale with custom adaptive grayscale
//
// Mixes the $grayscale-base-hue variable with a desaturated
// black and white grayscale to produce a grayscale with slight color saturation

$white: #fff;
$gray-100: #E4EDF5;
$gray-200: #CFE0EE;
$gray-300: #AECCE2;
$gray-400: #87B0D3;
$gray-500: #6A95C7;
$gray-600: #567EB9;
$gray-700: #4C6CA9;
$gray-800: #425A8B;
$gray-900: #394C6F;
$black: #000;

// Override Bootstrap default state colors

$primary: #005de7;
$secondary: #4c6ca9;
$success: #009e78;
$info: #48bdff;
$warning: #ffa82d;
$danger: #FF5029;
$dark: #161C21;
$light: #F3F8FB;

// Brand color variables
//
// Used to create custom button styles for popular brands
// You can add more brand color variables below, a good place to
// find official brand colors is https://brandcolors.net/

$facebook: #3b5998;
$twitter: #1da1f2;
$github: #333333;
$google: #dd4b39;
$clever: #3451ee;
