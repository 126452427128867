//
// Project styles
//

// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS
// for creating your own CSS components
@import './plasma/styles';
@import '../../node_modules/ngx-easy-table/style.css';

// importing core styling file
@import "./fontawesome/scss/fontawesome.scss";

// our project needs Solid + Brands
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";

ngb-popover-window.popover {
  border: 5px solid #3eb5f0 !important;
  .popover-body {
    padding: 20px;
  }
}
