@import 'styles/_plasma-scss-color-overrides.scss';

header.pg-header {
  margin-top: 60px; // Allows for sticky navbar

  h1, h2 {
    color: $white;
  }

  h1 {
    font-size: 2.5rem;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    max-width: 920px;
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .pg-hero-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.orange-curve-inverse {
  width: 100vw;
  height: 24px;
  overflow: hidden;
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  background-color: $white;

  img {
    width: 100%;
    height: 100%;
  }
}

.pg-blue-grad {
  background: linear-gradient(180deg, #0e2b5d, #04142E 100%);
}

section.pg-content {
  // Set min height since content may not fill full page
  // Hero 270, footer 150, orange curve 24
  min-height: calc(100vh - 444px);
  position: relative;
  height: 100%;
  background-color: $white;
  z-index: 1;

  h2, h3 {
    color: $text-700;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  p, h4 {
    color: $text-500;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  h2 {
    font-size: 2.25rem;
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 1.125rem;
    }
  }

  p {
    font-size: 1.125rem;
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .orange-line {
    border-top: 2px solid $warning;
    opacity: 1;
  }

  .pg-section {
    padding-top: 3.375rem;
    padding-bottom: 3.375rem;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding: 3.375rem 1.5rem;
      background-image: none;
    }

    .pg-container {
      width: 920px;
      @media screen and (max-width: 968px) {
        min-width: calc(100vw - 48px);
        margin-left: min(24px);
        margin-right: min(24px);
      }
    }
  }

  .pg-dyn-section {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding: 0px 1.5rem 3.375rem 1.5rem;
      background-image: none;
    }

    .pg-dyn-container {
      width: 546px;
      @media screen and (max-width: 594) {
        min-width: calc(100vw - 48px);
        margin-left: min(24px);
        margin-right: min(24px);
      }
      .breadcrumb-label {
        h1 {
          color: $text-700;
          font-family: Kanit;
          font-size: 36px;
          @media screen and (max-width: 768px) {
            font-size: 32px;
            font-weight: 400;
          }
        }
        hr.orange-line {
          margin-bottom: 2.25rem!important;
        }
      }
    }
  }
}

.pg-flex-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-started {
  margin-top: -1px;
  z-index: 10;
  background-color: #E4EDF5;

  @media screen and (max-width: 768px) {
    pg-lib-button {
      margin-bottom: 54px;
    }

  }
}

// Sets background of footer orange curve to white
// on any page that has a form
section.pg-orange-curve-spacing {
  .pg-orange-curve-bg {
    height:24px;
    margin-bottom: -24px;
    background-color: $white;
    z-index:-1;
  }
}
