// Soft color variations

$red-soft: mix($gray-100, $red, 50%);
$orange-soft: mix($gray-100, $orange, 50%);
$yellow-soft: mix($gray-100, $yellow, 50%);
$green-soft: mix($gray-100, $green, 50%);
$teal-soft: mix($gray-100, $teal, 50%);
$cyan-soft: mix($gray-100, $cyan, 50%);
$blue-soft: mix($gray-100, $blue, 50%);
$indigo-soft: mix($gray-100, $indigo, 50%);
$purple-soft: mix($gray-100, $purple, 50%);
$pink-soft: mix($gray-100, $pink, 50%);

$primary-soft: mix($gray-100, $primary, 50%);
$secondary-soft: mix($gray-100, $secondary, 50%);
$success-soft: mix($gray-100, $success, 50%);
$info-soft: mix($gray-100, $info, 50%);
$warning-soft: mix($gray-100, $warning, 50%);
$danger-soft: mix($gray-100, $danger, 50%);

$pg-midnight-soft: mix($gray-100, $pgMidnight, 50%);
$pg-lavender-soft: mix($gray-100, $pgLavender, 50%);
$pg-indigo-soft: mix($gray-100, $pgIndigo, 50%);
$pg-light-blue-soft: mix($gray-100, $pgLightBlue, 50%);
$pg-cyan-soft: mix($gray-100, $pgCyan, 50%);
$pg-light-green-soft: mix($gray-100, $pgLightGreen, 50%);
$pg-green-soft: mix($gray-100, $pgGreen, 50%);
$pg-dark-green-soft: mix($gray-100, $pgDarkGreen, 50%);
$pg-purple-soft: mix($gray-100, $pgPurple, 50%);
$pg-maroon-soft: mix($gray-100, $pgMaroon, 50%);
$pg-red-soft: mix($gray-100, $pgRed, 50%);
$pg-light-red-soft: mix($gray-100, $pgLightRed, 50%);
$pg-orange-soft: mix($gray-100, $pgOrange, 50%);
$pg-rust-soft: mix($gray-100, $pgRust, 50%);
$pg-yellow-soft: mix($gray-100, $pgYellow, 50%);
$pg-pink-soft: mix($gray-100, $pgPink, 50%);

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);
// scss-docs-end theme-colors-map

$expanded-colors: (
  'black': $black,
  'white': $white,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red-soft': $red-soft,
  'orange-soft': $orange-soft,
  'yellow-soft': $yellow-soft,
  'green-soft': $green-soft,
  'teal-soft': $teal-soft,
  'cyan-soft': $cyan-soft,
  'blue-soft': $blue-soft,
  'indigo-soft': $indigo-soft,
  'purple-soft': $purple-soft,
  'pink-soft': $pink-soft,
  'primary-soft': $primary-soft,
  'secondary-soft': $secondary-soft,
  'success-soft': $success-soft,
  'info-soft': $info-soft,
  'warning-soft': $warning-soft,
  'danger-soft': $danger-soft,
);

$plasma-colors: (
  'pg-midnight': $pgMidnight,
  'pg-lavender': $pgLavender,
  'pg-indigo': $pgIndigo,
  'pg-light-blue': $pgLightBlue,
  'pg-cyan': $pgCyan,
  'pg-light-green': $pgLightGreen,
  'pg-green': $pgGreen,
  'pg-dark-green': $pgDarkGreen,
  'pg-light-purple': $pgLightPurple,
  'pg-purple': $pgPurple,
  'pg-maroon': $pgMaroon,
  'pg-red': $pgRed,
  'pg-light-red': $pgLightRed,
  'pg-pink': $pgPink,
  'pg-orange': $pgOrange,
  'pg-rust': $pgRust,
  'pg-yellow': $pgYellow,
  'pg-midnight-soft': $pg-midnight-soft,
  'pg-lavender-soft': $pg-lavender-soft,
  'pg-indigo-soft': $pg-indigo-soft,
  'pg-light-blue-soft': $pg-light-blue-soft,
  'pg-cyan-soft': $pg-cyan-soft,
  'pg-light-green-soft': $pg-light-green-soft,
  'pg-green-soft': $pg-green-soft,
  'pg-dark-green-soft': $pg-dark-green-soft,
  'pg-purple-soft': $pg-purple-soft,
  'pg-maroon-soft': $pg-maroon-soft,
  'pg-red-soft': $pg-red-soft,
  'pg-light-red-soft': $pg-light-red-soft,
  'pg-pink-soft': $pg-pink-soft,
  'pg-orange-soft': $pg-orange-soft,
  'pg-rust-soft': $pg-rust-soft,
  'pg-yellow-soft': $pg-yellow-soft,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $expanded-colors);

$theme-colors: map-merge($theme-colors, $plasma-colors);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.9;
